import type { ClSdk } from "../../ClSdk";
import type { ClPlugin } from "../utils/ClPlugin";
import { noop } from "es-toolkit";
import { ClDebug } from "../../modules/ClDebug";

namespace ClPluginShopify {
  export interface Options extends ClPlugin.Options {}
}

class ClPluginShopify implements ClPlugin {
  private clSdk: ClSdk;
  private clDebug: ClDebug;
  "~hooks": NonNullable<ClPlugin["~hooks"]> = {
    beforeApplyFeatures: (features) => {
      return {
        ...features,
        clRe: false,
      };
    },
  };
  constructor(options: ClPluginShopify.Options) {
    this.clSdk = options.clSdk;
    this.clDebug = new ClDebug({
      clSdk: this.clSdk,
      module: "ClPluginShopify",
    });
    /**
     * Shopify plugin doesn't really do anything. Just a placeholder for now.
     */
    this.clDebug.debug("ClPluginShopify initialized");
  }
  destroy = noop;
}

const clPluginShopify = ((
  options?: ClPlugin.FactoryOptions<ClPluginShopify.Options>,
) => {
  return (
    inheritOptions: Pick<ClPluginShopify.Options, keyof ClPlugin.Options>,
  ) =>
    new ClPluginShopify({
      ...(options ?? {}),
      ...inheritOptions,
    });
}) satisfies ClPlugin.Factory;

export { ClPluginShopify, clPluginShopify };
